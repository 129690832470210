import React from 'react'
import Layout from '../components/layout/Layout'
import ContactForm from '../components/modules/ContactForm'
import Hero from '../components/modules/Hero'

const SubtitleItem = ({ children }) => {
  return (
    <p className="cool-sans text-4xl">{children}</p>
  )
}

const PlusItem = ({ children }) => {
  return (
    <p className="text-2xl"><span className="fas fa-check-square text-green-500"></span> {children}</p>
  )
}

export default function Quote() {
  return (
    <Layout title="Quote" showCTA={false}>
      <Hero
        title="Get a quote"
        subText="We deliver. Work with us to build your dream project into an amazing product your customers will enjoy."
        color="blue"
        showContactCTA={false}
      />
      <div className="grid md:grid-flow-col auto-cols-auto md:w-3/4">
        <section className="py-16 px-12">
          {/* <h2 className="text-3xl md:text-6xl mb-5">Want to talk to us?</h2> */}
          <div className="md:pt-20"></div>
          {/* Mild service info */}
          <SubtitleItem>We have...</SubtitleItem>
          <PlusItem>Trusted, UK Based Staff</PlusItem>
          <PlusItem>Robust Processes</PlusItem>
          <PlusItem>Seasoned Developers</PlusItem>
          <br />
          <SubtitleItem>We provide...</SubtitleItem>
          <PlusItem>App Design &amp; Development</PlusItem>
          <PlusItem>Full Stack Development</PlusItem>
          <PlusItem>Hybrid App Development</PlusItem>
        </section>
        <div className="py-8 md:py-16 px-12">
          {/* Form for quote */}
          <ContactForm quote={true} />
        </div>
      </div>
    </Layout>
  )
}
